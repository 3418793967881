import styled, {keyframes} from "styled-components";
import { layout, color, space } from 'styled-system'
import { useState } from 'react';


const Token = ({ color, onPlay }) => {

    return (
        <>
            <TokenElement className={`${color}`} onClick={() => onPlay()}></TokenElement>
        </>
    )
}

const TokenElement = styled.div`
    border-radius: 50%;
    margin: 3px;
    transition: background-color 0.3s;

    &.Pink {
        background-color: #BC84B0;
        border: 1px solid #BC84B0;
    }

    &.Orange {
        background-color: #F4A27F;
        border: 1px solid #F4A27F;
    }

    &.null {
        background-color: white;
        border: 1px solid #ccc;
    }

    @media ${props => props.theme.mediaQueries.mobile} {
        width: 35px;
        height: 35px;
    }
    @media ${props => props.theme.mediaQueries.tablet} {
        width: 48px;
        height: 48px;
    }
    @media ${props => props.theme.mediaQueries.desktop} {
        width: 50px;
        height: 50px;
    }
`

export default Token;
