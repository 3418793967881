export default [   
    {
        img: 'assets/jpg/lapeyre.jpg',
        label: 'Lapeyre',
        resume: 'Configurators of different types of furnitures ( bathroom, windows, kitchen...), offering a full custom experience.',
        tech: ['Angular', 'React', 'Redux', 'Java'],
        comment: '',
        link: 'https://www.lapeyre.fr/c/h/outil-creamix#'
    },
    {
        img: 'assets/jpg/fo.jpg',
        label: 'FO Crédit agricole nord de France',
        resume: 'Migration from an old Joomla project to a new wordpress website.',
        tech: ['Wordpress', 'Investory theme'],
        comment: 'not deployed yet',
        link: 'http://www.focandf.fr/',
    },
    {
        img: 'assets/jpg/plotly.jpeg',
        label: 'Auchan Retail',
        resume: 'Web application of reporting used to follow internals projects advancement.',
        tech: ['Angular', 'Plotly', 'Node.js'],
        comment: '',
        link: undefined
    },
    {
        img: 'assets/jpg/sharedcalendar.jpg',
        label: 'SharedCalendar',
        resume: 'Development from scratch to a calendar sharable with work teams or friends.',
        tech: ['React Native', 'Firebase'],
        comment: '',
        link: undefined
    },
    {
        img: 'assets/jpg/maif.jpg',
        label: 'Maif',
        resume: 'Web application allowing associations and companies to set up events and make them accessible to volunteers.',
        tech: ['Angular', 'Symfony'],
        comment: '',
        link: 'https://www.instantsbenevoles.fr/accueil',
        active: true
    },
    {
        img: undefined,
        label: 'Staffmatch',
        resume: 'Maintenance and improvement for a dashboard administration tool.',
        tech: ['Angular', 'Symfony'],
        comment: '',
        link: 'https://www.staffmatch.com/fr/'
    },
    {
        img: '',
        label: 'Boulanger',
        resume: 'Visual rework for a single page dashboard.',
        tech: ['Vue.js'],
        comment: '',
        link: 'https://www.boulanger.com/'
    },
    {
        img: 'assets/jpg/veepee.jpg',
        label: 'Veepee',
        resume: 'Return merchandise service proposed by Veepee, allowing to customers from Veepee to resell items which doesn\'t fit',
        tech: ['Vue.js'],
        comment: '',
        link: 'https://www.veepee.fr/re-turn',
    },
    {
        img: 'assets/jpg/biig.jpg',
        label: 'Biig',
        resume: 'Dashboard and managing customer’s tool for insurance and bank companies.',
        tech: ['Angular', 'Symfony'],
        comment: '',
        link: 'https://biig.fr/nous-sommes-biig'
    }
    // {
    //     img: '',
    //     label: 'Transport prestige',
    //     resume: '',
    //     tech: ['Vue.js'],
    //     comment: 'not online anymore',
    //     link: undefined
    // },
    
]