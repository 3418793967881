import styled from "styled-components";
import { MainCard } from "../main-card/main-card";
import { layout, color } from 'styled-system';

export const BackgroundGrid = () => {

    return (
        <BackgroundThemed bg="darkBlue">
            <SpacingBlock />
            <MainCard />
            <SpacingBlock />
        </BackgroundThemed>
    )
}

const BackgroundThemed = styled.div`
    ${color};
    ${layout};
    height: 100vh;
    display: grid;
    overflow-x: hidden;
    @media ${props => props.theme.mediaQueries.mobile} {
        grid-template-columns: repeat(12, 1fr);
        height: 90vh;
    };
    @media ${props => props.theme.mediaQueries.tablet} {
        grid-template-columns: repeat(12, 1fr);
    };
    @media ${props => props.theme.mediaQueries.desktop} {
        grid-template-columns: repeat(24, 1fr);
    };
`

const SpacingBlock = styled.div`
    @media ${props => props.theme.mediaQueries.mobile} {
        grid-column: auto / span 2;
    };
    @media ${props => props.theme.mediaQueries.tablet} {
        grid-column: auto / span 3;
    };
    @media ${props => props.theme.mediaQueries.desktop} {
        grid-column: auto / span 6;
    };
`