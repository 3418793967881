import styled from "styled-components";
import { color } from 'styled-system';


export const BurgerButton = ({isOpen, onOpen}) => {

    return (
        <StyledButton id="nav-burger" onClick={onOpen} className={isOpen ? 'open' : ''}>
            <StyledChildElement bg="light"></StyledChildElement>
            <StyledChildElement bg="light"></StyledChildElement>
            <StyledChildElement bg="light"></StyledChildElement>
            <StyledChildElement bg="light"></StyledChildElement>
            <StyledChildElement bg="light"></StyledChildElement>
            <StyledChildElement bg="light"></StyledChildElement>
        </StyledButton>
    )
}

const StyledButton = styled.div`
    width: 35px;
    height: 45px;
    margin: 25px 25px;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: all .5s ease-in-out;
    -moz-transition: all .5s ease-in-out;
    -o-transition: all .5s ease-in-out;
    transition: all .5s ease-in-out;
    cursor: pointer;
    z-index: 1000;

    @media ${props => props.theme.mediaQueries.tablet} {
        display: none;
    };
`

const StyledChildElement = styled.span`
    ${color};
    display: block;
    position: absolute;
    height: 5px;
    width: 50%;
    opacity: 1;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: all .25s ease-in-out;
    -moz-transition: all .25s ease-in-out;
    -o-transition: all .25s ease-in-out;
    transition: all .25s ease-in-out;
`
