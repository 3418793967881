export const PinkMecanisme = () => {
    return (
        <>
            <path d="M97.7279 137.823C89.7458 137.823 81.943 135.456 75.3061 131.021C68.6693 126.587 63.4965 120.283 60.4419 112.909C57.3873 105.535 56.5881 97.4199 58.1453 89.5912C59.7025 81.7625 63.5462 74.5714 69.1904 68.9272C74.8346 63.283 82.0257 59.4393 89.8544 57.8821C97.6831 56.3249 105.798 57.1241 113.172 60.1787C120.547 63.2333 126.85 68.4061 131.284 75.0429C135.719 81.6798 138.086 89.4826 138.086 97.4646C138.086 102.765 137.042 108.013 135.014 112.909C132.986 117.805 130.013 122.254 126.265 126.002C122.518 129.75 118.069 132.722 113.172 134.751C108.276 136.779 103.028 137.823 97.7279 137.823V137.823ZM97.7279 63.2481C90.9605 63.2481 84.345 65.2548 78.7181 69.0146C73.0913 72.7744 68.7056 78.1183 66.1159 84.3705C63.5261 90.6228 62.8485 97.5026 64.1687 104.14C65.489 110.777 68.7478 116.874 73.5331 121.659C78.3184 126.445 84.4152 129.704 91.0525 131.024C97.6899 132.344 104.57 131.666 110.822 129.077C117.074 126.487 122.418 122.101 126.178 116.474C129.938 110.847 131.944 104.232 131.944 97.4646C131.942 88.3914 128.336 79.6905 121.92 73.2755C115.503 66.8606 106.801 63.2568 97.7279 63.2568V63.2481Z" fill="url(#paint0_linear_pink)"/>
            <path d="M118.003 198H80.8213L79.944 175.566C72.5682 173.826 65.4781 171.043 58.8876 167.302L42.4637 182.462L16.1432 156.142L30.6458 140.455C26.3886 133.78 23.1562 126.505 21.0564 118.872L0 118.038V80.8477L20.0913 80.0493C21.8656 71.9864 24.8848 64.2491 29.0402 57.1154L15.5115 42.4549L41.832 16.1344L56.0801 29.2947C63.2002 24.8536 70.9794 21.5695 79.1281 19.5649L79.9089 0H117.091L117.872 19.6351C125.992 21.7956 133.729 25.2022 140.806 29.7333L155.519 15.5466L181.839 41.8671L167.74 57.0891C171.745 63.9548 174.702 71.3801 176.514 79.1193L197.974 79.9966V117.187L175.961 118.065C174.019 125.422 171.032 132.463 167.091 138.972L182.436 155.58L156.115 181.901L139.446 166.477C133.041 170.295 126.134 173.198 118.925 175.101L118.003 198ZM86.7171 191.859H112.108L112.985 170.162L115.266 169.635C123.395 167.744 131.142 164.48 138.174 159.985L140.174 158.712L155.966 173.32L173.882 155.378L159.335 139.586L160.634 137.586C165.242 130.457 168.584 122.585 170.513 114.319L171.048 112.037L191.797 111.213V85.8836L171.451 85.0677L170.978 82.7076C169.229 74.0663 165.939 65.8104 161.265 58.3349L160.028 56.3433L173.294 42.0162L155.352 24.0569L141.315 37.0241L139.305 35.6905C131.697 30.6313 123.211 27.0369 114.283 25.0922L111.967 24.5921L111.23 6.16776H85.8398L85.1116 24.5921L82.7778 25.0834C73.7231 27.0223 65.117 30.657 57.4137 35.7958L55.4397 37.0767L41.9724 24.6798L24.0306 42.6304L36.7083 56.3609L35.4712 58.3437C30.6449 66.0751 27.3007 74.6378 25.6098 83.5938L25.1536 85.9977L6.11512 86.761V112.143L25.8906 112.932L26.4345 115.178C28.5257 123.709 32.127 131.797 37.068 139.06L38.4366 141.078L24.6447 155.993L42.5953 173.943L58.0717 159.669L60.0457 160.871C67.278 165.279 75.2179 168.405 83.5148 170.109L85.8749 170.592L86.7171 191.859Z" fill="url(#paint1_linear_pink)"/>
            <defs>
            <linearGradient id="paint0_linear_pink" x1="97.7278" y1="57.1066" x2="97.7278" y2="137.823" gradientUnits="userSpaceOnUse">
            <stop offset="0.291667" stopColor="#BC84B0"/>
            <stop offset="0.651042" stopColor="#AB86C4"/>
            <stop offset="1" stopColor="#9A87D8"/>
            </linearGradient>
            <linearGradient id="paint1_linear_pink" x1="98.9868" y1="0" x2="98.9868" y2="198" gradientUnits="userSpaceOnUse">
            <stop offset="0.291667" stopColor="#BC84B0"/>
            <stop offset="0.651042" stopColor="#AB86C4"/>
            <stop offset="1" stopColor="#9A87D8"/>
            </linearGradient>
            </defs>
        </>
    )
}