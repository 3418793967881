import React from 'react';
import styled, {keyframes, css} from "styled-components";
import { Title } from '../../../components/fonts/title'


const PlayerChoice = ({ onChoice }) => {
    return (
        <PlayerChoiceContainer>
            <Title fontColor="darkBlue"
                    fontContent="Pick your player" 
                    fontType="intro" 
                    fontAlign="center"
                    fontMb="30px"
                    widthCustom="100%">
            </Title>
            <PlayerChoiceButton onClick={() => onChoice('X')}>X</PlayerChoiceButton>
            <PlayerChoiceButton onClick={() => onChoice('O')}>O</PlayerChoiceButton>
        </PlayerChoiceContainer>
    );
};

const PlayerChoiceContainer = styled.div`
    text-align: center;
    margin-top: 50px;
`

const PlayerChoiceButton = styled.button`
    font-size: 24px;
    width: 60px;
    height: 60px;
    margin: 10px;
    cursor: pointer;
    border: 1px solid #5D6996;
    border-radius: 5px;
    background-color: #F6F8FA;
    fontFamily: 'Amulya-Bold , Arial, sans-serif';
    color: #5D6996;
    text-align: center;
    padding: 0;

    @media ${props => props.theme.mediaQueries.mobile} {
        font-size: 25px;
    }
    @media ${props => props.theme.mediaQueries.tablet} {
        font-size: 40px;
    }
    @media ${props => props.theme.mediaQueries.desktop} {
        font-size: 40px;
    }
    
`

export default PlayerChoice;