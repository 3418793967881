export const OrangeMecanisme = () => {
    return (
        <>
            <path d="M94.5002 347L61.0002 313L87.0002 285L123.5 320L94.5002 347Z" fill="#5D6996" stroke="#5D6996"/>
            <path d="M24.8506 228.666L24.8757 180.935L63.0488 179.258L64.4574 229.807L24.8506 228.666Z" fill="#5D6996" stroke="#5D6996"/>
            <path d="M227.588 381.277L179.857 381.066L178.329 342.887L228.883 341.674L227.588 381.277Z" fill="#5D6996" stroke="#5D6996"/>
            <path d="M177.635 25.5407L225.891 26.6637L227.5 65.294L176.391 65.5555L177.635 25.5407Z" fill="#5D6996" stroke="#5D6996"/>
            <path d="M311.355 60.1661L344.659 94.3574L318.499 122.208L282.2 86.9996L311.355 60.1661Z" fill="#5D6996" stroke="#5D6996"/>
            <path d="M346.097 312L312.21 345.614L284.123 319.708L319 283.091L346.097 312Z" fill="#5D6996" stroke="#5D6996"/>
            <path d="M380.139 179.325L380.205 227.056L342.035 228.806L340.53 178.259L380.139 179.325Z" fill="#5D6996" stroke="#5D6996"/>
            <path d="M58.3177 94.498L92.6826 61.3725L120.396 87.6776L84.9984 123.792L58.3177 94.498Z" fill="#5D6996" stroke="#5D6996"/>
            <path d="M201.5 343C174.032 343 147.164 335.089 124.124 320.243C123.524 319.856 122.926 319.465 122.331 319.069C108.398 309.792 96.2929 298.191 86.5002 284.855C79.8225 275.761 74.2202 265.861 69.8474 255.341C59.0619 229.394 56.24 200.843 61.7383 173.297C67.2367 145.752 80.8085 120.45 100.738 100.591C120.666 80.7318 146.058 67.2077 173.7 61.7286C201.342 56.2495 229.994 59.0615 256.033 69.8092C282.071 80.5568 304.327 98.7573 319.985 122.109C335.643 145.461 344 172.915 344 201C344 219.648 340.314 238.113 333.153 255.341C325.992 272.569 315.495 288.223 302.263 301.409C289.031 314.595 273.321 325.055 256.033 332.191C238.744 339.327 220.214 343 201.5 343Z" fill="#5D6996"/>
            <path d="M200.411 278.3C185.104 278.3 170.14 273.761 157.413 265.256C144.686 256.752 134.766 244.665 128.908 230.523C123.051 216.381 121.518 200.82 124.504 185.807C127.49 170.794 134.861 157.004 145.685 146.18C156.509 135.357 170.299 127.986 185.312 124.999C200.325 122.013 215.886 123.546 230.028 129.403C244.17 135.261 256.257 145.181 264.761 157.908C273.265 170.636 277.805 185.599 277.805 200.906C277.805 211.069 275.803 221.133 271.913 230.523C268.024 239.913 262.323 248.445 255.136 255.632C247.95 262.818 239.418 268.519 230.028 272.408C220.638 276.298 210.574 278.3 200.411 278.3ZM200.411 135.289C187.433 135.289 174.747 139.138 163.956 146.348C153.166 153.558 144.755 163.806 139.789 175.796C134.823 187.785 133.523 200.979 136.055 213.707C138.587 226.435 144.836 238.127 154.013 247.304C163.19 256.48 174.881 262.73 187.61 265.262C200.338 267.793 213.531 266.494 225.521 261.528C237.511 256.561 247.759 248.151 254.969 237.36C262.179 226.57 266.027 213.884 266.027 200.906C266.023 183.506 259.108 166.821 246.803 154.519C234.498 142.217 217.81 135.306 200.411 135.306V135.289Z" fill="url(#paint0_linear_orange)"/>
            <path d="M239.293 393.701H167.989L166.307 350.68C152.163 347.342 138.566 342.005 125.928 334.831L94.4317 363.904L43.9575 313.43L71.7688 283.347C63.6048 270.547 57.4061 256.596 53.3794 241.958L13 240.36V169.04L51.5287 167.509C54.9312 152.047 60.721 137.209 68.6899 123.529L42.7461 95.4149L93.2203 44.9407L120.544 70.1778C134.198 61.6612 149.116 55.3635 164.742 51.5192L166.24 14H237.543L239.04 51.6538C254.613 55.7971 269.449 62.3297 283.02 71.019L311.235 43.8134L361.709 94.2876L334.672 123.479C342.352 136.645 348.023 150.884 351.497 165.725L392.65 167.408V238.728L350.437 240.41C346.713 254.519 340.985 268.021 333.427 280.504L362.853 312.353L312.379 362.827L280.412 333.249C268.131 340.571 254.884 346.138 241.059 349.788L239.293 393.701ZM179.296 381.923H227.986L229.669 340.316L234.043 339.306C249.632 335.679 264.488 329.42 277.973 320.799L281.809 318.359L312.093 346.373L346.449 311.966L318.554 281.681L321.044 277.845C329.881 264.174 336.291 249.079 339.989 233.226L341.015 228.852L380.805 227.27V178.697L341.789 177.133L340.88 172.607C337.527 156.036 331.218 140.203 322.255 125.868L319.883 122.048L345.322 94.5737L310.915 60.1334L283.996 85.0004L280.143 82.443C265.553 72.7411 249.279 65.8482 232.159 62.1188L227.717 61.1597L226.304 25.8278H177.613L176.217 61.1597L171.741 62.1019C154.377 65.8201 137.873 72.7903 123.101 82.6449L119.315 85.1013L93.4895 61.328L59.083 95.7514L83.3947 122.082L81.0224 125.884C71.7671 140.711 65.3541 157.131 62.1114 174.306L61.2365 178.916L24.7269 180.38V229.054L62.6498 230.568L63.6929 234.875C67.7032 251.235 74.6093 266.745 84.0845 280.672L86.7092 284.542L60.2607 313.144L94.6841 347.567L124.363 320.193L128.148 322.498C142.018 330.953 157.244 336.947 173.155 340.215L177.68 341.14L179.296 381.923Z" fill="url(#paint1_linear_orange)"/>
            <defs>
            <linearGradient id="paint0_linear_orange" x1="186.62" y1="123.512" x2="250.005" y2="278.428" gradientUnits="userSpaceOnUse">
            <stop stopColor="#F4A27F"/>
            <stop offset="0.796875" stopColor="#BC84B0"/>
            <stop offset="1" stopColor="#9A87D8"/>
            </linearGradient>
            <linearGradient id="paint1_linear_orange" x1="169" y1="14" x2="324.5" y2="394" gradientUnits="userSpaceOnUse">
            <stop stopColor="#F4A27F"/>
            <stop offset="0.796875" stopColor="#BC84B0"/>
            <stop offset="1" stopColor="#9A87D8"/>
            </linearGradient>
            </defs>
        </>
    )
}