import styled from "styled-components";
import { layout, color, space } from 'styled-system'

import restart from "../../assets/svg/restart.svg";

const RestartButtonComponent = ({resetGame}) => {
    return (

        <RestartButton bg="darkBlue" onClick={() => resetGame()} >
            <Input
                height={["27px"]} 
                width={["27px"]}
                type="image"
                src={restart}/>
        </RestartButton>
    )
}

const Input = styled.input`
    ${layout};
`

const RestartButton = styled.button`
    ${space};
    ${color}
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0;
    width: 40px;
    height: 40px;
    cursor: pointer;
    border: none;
    border-radius: 50%;
    transition: background-color 0.3s;
    position: absolute;
    right: 15px;
    top: 15px;
    &:hover {
        background-color: #F4A27F;
    }
`

export default RestartButtonComponent;
