import styled, {css, keyframes} from "styled-components";
import { space, layout, position } from 'styled-system';
import { OrangeMecanisme } from './svg/orange-mecanisme'
import { PurpleMecanisme } from './svg/purple-mecanisme'
import { PinkMecanisme } from './svg/pink-mecanisme'


export const Mecanismes = () => {

    return (
        <>
            <Mecanisme height="13rem"
                        width="13rem"
                        left={["-5rem", "-5rem", "-8rem", "-8rem"]} 
                        top={["20rem", "20rem", "25rem", "12rem"]} 
                        animation={rotate2}
                        animationLength={"2.5s"}
                        transformOrigin={"bottom right"}
                        animationDelay={"0.5s"}>
                <PinkMecanisme></PinkMecanisme>
            </Mecanisme>
            <Mecanisme height="25rem"
                        width="25rem"
                        left="-12rem"
                        top={["-2rem", "-2rem", "4rem", "-7rem"]}
                        animation={rotateClock}
                        animationLength={"4s"}
                        transformOrigin={"bottom right"}
                        animationDelay={"0.5s"}>
                <OrangeMecanisme></OrangeMecanisme>
            </Mecanisme>
            <Mecanisme height="11rem"
                        width="11rem"
                        bottom={["4rem", "4rem", "10rem", "3rem"]} 
                        right="-5rem"
                        animation={rotate3}
                        animationLength={"3.5s"}
                        transformOrigin={"bottom right"}
                        animationDelay={"0.5s"}>
                <PurpleMecanisme></PurpleMecanisme>
            </Mecanisme>
        </>
    )
}

const rotateClock = keyframes`
    0% { transform: rotate(180deg); visibility: visible;}
    100% { transform: rotate(0deg); visibility: visible;}
`;
const rotate2 = keyframes`
    0% { transform: rotate(0deg); visibility: visible;}
    100% { transform: rotate(90deg); visibility: visible;}
`;
const rotate3 = keyframes`
    0% { transform: rotate(70deg); visibility: visible;}
    100% { transform: rotate(0deg); visibility: visible;}
`;

const Mecanisme = styled.svg`
    ${space};
    ${position};
    ${layout};
    position: absolute;
    z-index: 2;
    filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.25));
    animation: ${({ animation, animationLength }) =>
        animation && animationLength && css`
        ${animation} ${animationLength}}
    `};
    animation-delay: ${({ animationDelay }) =>
        animationDelay && css`
        ${animationDelay}
    `};
    visibility: ${({ animation }) => animation && "hidden"};
    animation-fill-mode: forwards;
`