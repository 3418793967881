import styled, {keyframes} from "styled-components";
import { layout, color, space } from 'styled-system'
import { useState } from 'react';

import RestartButtonComponent from "../restart-button";
import GameContainer from '../game-container';
import Token from "./token";
import { Title } from '../../../components/fonts/title'

const Puissance4Page = () => {
    const [board, setBoard] = useState([...Array(6)].map(() => Array(7).fill(null)));
    const [currentPlayer, setCurrentPlayer] = useState('Pink');
    const [winner, setWinner] = useState(null);

    const directions = [
        { x: 0, y: 1 },  // Horizontal
        { x: 1, y: 0 },  // Vertical
        { x: 1, y: 1 },  // Diagonal montante
        { x: 1, y: -1 }  // Diagonal descendante
      ];

    const resetGame = () => {
        setBoard([...Array(6)].map(() => Array(7).fill(null)));
        setCurrentPlayer('Pink');
        setWinner(null);
    }

    const checkWinner = (board, row, col, currentPlayer) => {
        for (let dir of directions) {
          let count = 1;  // compte pour le jeton courant
      
          // vérification dans une direction
          for (let i = 1; i < 4; i++) {
            const x = row + dir.x * i;
            const y = col + dir.y * i;
      
            if (x >= 0 && x < 6 && y >= 0 && y < 7 && board[x][y] === currentPlayer) {
              count++;
            } else {
              break;
            }
          }
      
          // vérification dans la direction opposée
          for (let i = 1; i < 4; i++) {
            const x = row - dir.x * i;
            const y = col - dir.y * i;
      
            if (x >= 0 && x < 6 && y >= 0 && y < 7 && board[x][y] === currentPlayer) {
              count++;
            } else {
              break;
            }
          }
      
          if (count >= 4) return true;
        }
        
        return false;
      }
    
    const play = (column) => {
        if (!!winner) { return; }
        let newBoard = [...board];
        let currentRow = null;
        for (let i = 5; i >= 0; i--) {
            if (!newBoard[i][column]) {
                newBoard[i][column] = currentPlayer;
                currentRow = i;
                setBoard(newBoard);
                setCurrentPlayer(currentPlayer === 'Pink' ? 'Orange' : 'Pink');
                
                if (checkWinner(newBoard, currentRow, column, currentPlayer)) {
                    setWinner(currentPlayer);
                    // alert(currentPlayer + ' a gagné!');
                // Vous pouvez ajouter du code pour gérer la fin de la partie si vous le souhaitez
                }
                return;
            }
        }

    
        alert("You can't play in a fulled column");
        
     }

    const getStatus = () => {
        let content = '';
        if (!!winner) {
            const winner = currentPlayer === 'Pink' ? 'Orange' : 'Pink';
            content = `${winner} player won !`
        } else {
            content = `Next player: ${currentPlayer}`
        }
        return (
            <Title fontColor="darkBlue"
                    fontContent={content} 
                    fontType="infos" 
                    fontAlign="center"
                    fontMb="30px"
                    widthCustom="100%">
            </Title>
        )
    }

    return (
        <>
            <GameContainer >
                <div>

                    {getStatus()}
                    <BoardElement>
                        {board.map((row, rowIndex) => (
                            <Column key={rowIndex}>
                                {row.map((cell, columnIndex) => (
                                    <Token key={columnIndex}  color={cell} onPlay={() => play(columnIndex)} />
                                ))}
                            </Column>
                        ))}
                    </BoardElement>
                </div>
                <RestartButtonComponent resetGame={() => resetGame()}></RestartButtonComponent>
            </GameContainer>
        </>
    )
}


const BoardElement = styled.div`
    display: flex;
    flex-direction: column;
    border: 1px solid #ccc;
    border-radius: 10px;
    overflow: hidden;
    min-height: 200px;

    @media ${props => props.theme.mediaQueries.mobile} {
    }
    @media ${props => props.theme.mediaQueries.tablet} {
    }
    @media ${props => props.theme.mediaQueries.desktop} {
    }
`

const Column = styled.div`
    display: flex;
`


export default Puissance4Page;
