import styled, {keyframes} from "styled-components";
import { layout, color, space } from 'styled-system'
import { useEffect, useState } from 'react';

import DifficultyChoice from "./difficulty-choice";
import restart from "../../../assets/svg/restart.svg";
import GameContainer from '../game-container';
import { Title } from '../../../components/fonts/title'
import RestartButtonComponent from '../restart-button';


const MemoryPage = () => {
    const [difficultyChoice, setDifficultyChoice] = useState(null);
    const [cards, setCards] = useState([]);
    const [flippedIndices, setFlippedIndices] = useState([]);
    const [movementCounter, setMovementCounter] = useState(0);
    const [discorveredPairs, setDiscoveredPairs] = useState([]);
    const [flexBasis, setFlexBasis] = useState(undefined);

    useEffect(() => {
        if (!!flippedIndices && flippedIndices.length === 2) {
            setTimeout(() => {
                setFlippedIndices([]);
                setMovementCounter(movementCounter + 1)
            }, 1000)
        }
    }, [flippedIndices])
    useEffect(() => {
        if (!!difficultyChoice) {
            let initialCards = [];
            for (let i = 1; i <= difficultyChoice/2; i++) {
                initialCards.push(i); 
                initialCards.push(i);
            }
            setCards(shuffle(initialCards));

            if (difficultyChoice === 16) {
                setFlexBasis('23%');
            } else if (difficultyChoice === 20) {
                setFlexBasis('18%');
            } else if (difficultyChoice === 36) {
                setFlexBasis('18%');
                // setFlexBasis('13%')
            }

        }
    }, [difficultyChoice])

    function shuffle(array) {
        const arr = [...array];
        for (let i = arr.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          [arr[i], arr[j]] = [arr[j], arr[i]];
        }
        return arr;
    }

    function handleCardClick(index) {
        if (index === flippedIndices[0] || discorveredPairs.includes(index)) { return; }

        if (flippedIndices.length === 2) {
          setFlippedIndices([]);
          setMovementCounter(movementCounter + 1)
        } else {
          setFlippedIndices([...flippedIndices, index]);
        }
        
        if (flippedIndices.length === 1 && cards[flippedIndices[0]] === cards[index]) {
            setDiscoveredPairs([...discorveredPairs, flippedIndices[0], index]);
        }
      }

    const getMovementNbr = () => {
        const content = `${movementCounter} movements played`
        return (
            <Title fontColor="darkBlue"
                fontContent={content} 
                fontType="infos"
                 
                fontAlign="center"
                widthCustom="100%">   
            </Title>
        )
        
    }

    const resetGame = () => {
        setMovementCounter(0);
        setCards([]);
        setFlippedIndices([]);
        setDiscoveredPairs([]);
        setDifficultyChoice(null);
    };

    return (
        <>
            <GameContainer >
                {
                    difficultyChoice === null && <>
                        <DifficultyChoice onChoice={(choice) => setDifficultyChoice(choice)} />
                    </>
                }

                {difficultyChoice && 
                    <>
                        <CardsContainer>
                            {cards.map((card, index) => (
                                <CardButton key={index} onClick={() => handleCardClick(index)} className={`${(flippedIndices.includes(index) || discorveredPairs.includes(index) ? 'flipped' : ''   )}`} style={{flexBasis : flexBasis}}>
                                {(flippedIndices.includes(index) || discorveredPairs.includes(index)) ? card : "?"}
                                </CardButton>
                            ))}
                        </CardsContainer>
                        {getMovementNbr()}

                        <RestartButtonComponent resetGame={() => resetGame()}></RestartButtonComponent>

                    </>
                }
                
            </GameContainer>
        </>
    )
}

const CardButton = styled.button`
    font-size: 24px;
    // width: 60px;
    // height: 60px;
    margin: 1%;
    cursor: pointer;
    border: 1px solid #5D6996;
    border-radius: 5px;
    background-color: #F6F8FA;
    font-family: Amulya-Medium , Arial, sans-serif;
    color: #5D6996;
    text-align: center;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    @media ${props => props.theme.mediaQueries.mobile} {
        font-size: 18px;
        min-width: 23px;
        height: 35px;
    }
    @media ${props => props.theme.mediaQueries.tablet} {
        font-size: 34px;
        min-width: 45px;
        height: 45px;
    }
    @media ${props => props.theme.mediaQueries.desktop} {
        font-size: 34px;
        min-width: 45px;
        height: 45px;
    }

    &.flipped {
        color: #F4A27F;
        border: 1px solid #F4A27F;
    }

`
const CardsContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 20px;
`

export default MemoryPage;
