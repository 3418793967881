import React from 'react';
import styled, {keyframes, css} from "styled-components";
import { Title } from '../../../components/fonts/title'


const DifficultyChoice = ({ onChoice }) => {
    return (
        <DifficultyChoiceContainer>
            <Title fontColor="darkBlue"
                    fontContent="Pick your difficulty" 
                    fontType="intro" 
                    fontAlign="center"
                    fontMb="30px"
                    widthCustom="100%">
            </Title>
            <DifficultyChoiceButton onClick={() => onChoice(16)}>easy</DifficultyChoiceButton>
            <DifficultyChoiceButton onClick={() => onChoice(20)}>medium</DifficultyChoiceButton>
            <DifficultyChoiceButton onClick={() => onChoice(30)}>hard</DifficultyChoiceButton>
        </DifficultyChoiceContainer>
    );
};

const DifficultyChoiceContainer = styled.div`
    text-align: center;
    margin-top: 50px;
`

const DifficultyChoiceButton = styled.button`
    font-size: 24px;
    width: 100px;
    height: 60px;
    margin: 10px;
    cursor: pointer;
    border: 1px solid #5D6996;
    border-radius: 5px;
    background-color: #F6F8FA;
    fontFamily: 'Amulya-Bold , Arial, sans-serif';
    color: #5D6996;
    text-align: center;
    padding: 0;
    
`

export default DifficultyChoice;