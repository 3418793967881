
import styled, {css, keyframes} from "styled-components";
import { layout, color, space } from 'styled-system'

import { Title } from '../fonts/title.js'

export const XpCard = ({ cardHeight, cardBg, xpData, animationLength}) => {

    const slideIn = keyframes`
        0% { 
            opacity: 0;
            transform: translateY(100%);
        } 
        100% { 
            opacity: 1;
            transform: translateY(0);
        }
    `;

    const XpBlock = styled.div`
        ${space};
        ${color};
        ${layout};
        animation-delay: O.75s;
        animation-fill-mode: forwards;
        box-shadow: 4px 4px 7px rgba(0, 0, 0, 0.25);
        &:hover {
            #coverImg {
                background: linear-gradient(180deg, rgba(244, 162, 127, 0.9) 0%, rgba(194, 147, 176, 0.9) 77.17%, rgba(154, 135, 216, 0.9) 100%);
                opacity: 100%
            }
        }
        position: relative;
        border-radius: 5px;
        display: flex;
        align-items: center;
        @media ${props => props.theme.mediaQueries.mobile} {
            margin-top: ${props => props.mtsm ? '40px' : '20px'};
            animation: ${slideIn} 2s;
        };
        @media ${props => props.theme.mediaQueries.tablet} {
            margin-top: ${props => props.mtsm ? '40px' : '20px'};
            animation: ${({ animationLength }) =>
            animationLength && css`
            ${slideIn} ${animationLength}}
        `};
        };
        @media ${props => props.theme.mediaQueries.desktop} {
            margin-top: ${props => props.mtsm ? '60px' : '20px'};
            animation: ${({ animationLength }) =>
            animationLength && css`
            ${slideIn} ${animationLength}}
        `};
        };
        @media ${props => props.theme.mediaQueries.large} {
            margin-top: ${props => props.mtsm ? '110px' : '40px'};
        };
    `
    const XpPicture = styled.div`
        border-radius: 5px;
        position: absolute;
        width: 96%;
        left: 2%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
        background-image: ${({img}) => css` url(${img})`};
        background-position: center;
        background-size: cover;
        @media ${props => props.theme.mediaQueries.mobile} {
            height: 165px;
            top: -25px;
        };
        @media ${props => props.theme.mediaQueries.tablet} {
            height: 100px;
            top: -15px;
        };
        @media ${props => props.theme.mediaQueries.desktop} {
            height: 200px;
            top: -35px;
        };
        @media ${props => props.theme.mediaQueries.large} {
            height: 250px;
            top: -70px;
        };
    `

    const CoverCard = styled.div`
        transition: 0.4s;
        border-radius: 5px;
        height: 100%;
        width: 100%;
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        opacity: 0;
        &:hover {
            background: linear-gradient(180deg, rgba(244, 162, 127, 0.9) 0%, rgba(194, 147, 176, 0.9) 77.17%, rgba(154, 135, 216, 0.9) 100%);
            opacity: 100%
        }
    `

    const formatedTech = (technologies) => {
        return technologies.join(' - ');
    }

    const hasImage = (img) => {
        return !!img && img.length > 0
    }

    return (
        <>
            <XpBlock height={cardHeight} bg={cardBg} mtsm={hasImage(xpData.img)} img={xpData.img} animationLength={animationLength}>
            {(xpData && !xpData.img) &&
            <>
                <CoverCard>
                    <Title fontColor="light" 
                            fontContent={xpData.label} 
                            fontType="label"
                            fontAlign="center">
                    </Title>
                    <Title fontColor="light" 
                            fontContent={formatedTech(xpData.tech)} 
                            fontType="smallItalic"
                            fontAlign="center">
                    </Title>
                    <Title fontColor="light" 
                            fontContent={xpData.comment} 
                            fontType="comment"
                            fontAlign="center">
                    </Title>
                </CoverCard>
                <Title fontColor={cardBg==="light" ? "darkBlue" : "light"}
                       fontContent={xpData.resume} 
                       fontType="smallContent"
                       fontPx={["10%"]}
                       fontAlign="center">
                </Title>
            </>
            }
            {(xpData && xpData.img) && 
            <>
                <XpPicture img={xpData.img} id="xpImg">
                    <CoverCard id="coverImg">
                        <Title fontColor="light"
                                fontContent={xpData.label} 
                                fontType="label"
                                fontAlign="center">
                        </Title>
                        <Title fontColor="light" 
                                fontContent={formatedTech(xpData.tech)} 
                                fontType="smallItalic"
                                fontAlign="center">
                        </Title>
                        <Title fontColor="light" 
                            fontContent={xpData.comment} 
                            fontType="comment"
                            fontAlign="center">
                        </Title>
                    </CoverCard>
                </XpPicture>
                <Title fontColor={cardBg==="light" ? "darkBlue" : "light"}
                       fontContent={xpData.resume} 
                       fontType="smallContent"
                       fontPt={["140px", "140px", "85px", "170px"]}
                       fontPx={["10%"]}
                       fontAlign="center">
                </Title>
            </>
            }
            </XpBlock>
        </>
    )
}