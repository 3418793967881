import styled, {css} from "styled-components"
import { variant, color, typography, position, space, layout } from 'styled-system'

import { XpCard } from '../components/xp-card/xp-card'
import xpData from '../shared/xpData'

const PlaygroundPage = () => {

    return (
        <>
            <BackgroundGrid bg="darkBlue" pt={["5rem", "5rem", "9rem", "5rem"]}>
                <GameContainer>
            
                    {/* <GameCard bg="orange">
                        <GameLink href="/snake" color="light">
                            Snake
                        </GameLink>
                    </GameCard> */}

                    <GameCard bg="purple">
                        <GameLink href="/memory" color="light">
                            Memory
                        </GameLink>
                    </GameCard>

                    <GameCard bg="pink">
                        <GameLink href="/tictactoe" color="light">
                            Tic-Tac-Toe
                        </GameLink>
                    </GameCard>

                    <GameCard bg="orange">
                        <GameLink href="/puissance4" color="light">
                            Puissance 4
                        </GameLink>
                    </GameCard>


                    {/* <Title fontColor="light"
                                fontContent={xpData.label} 
                                fontType="label"
                                fontAlign="center">
                        </Title> */}

                        
                </GameContainer>
            </BackgroundGrid>
        </>
    )
}

export default PlaygroundPage;

const BackgroundGrid = styled.div`
    ${color};
    ${layout};
    ${space};
    margin: auto;
    text-align: center;
    margin-top: 10px;
    transform: rotate(0deg);
    -webkit-transition: all .25s ease-in-out;
    -moz-transition: all .25s ease-in-out;
    -o-transition: all .25s ease-in-out;
    transition: all .25s ease-in-out;
`

const GameContainer = styled.div`
    ${color};
    ${layout};
    ${space};
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-around;

    @media ${props => props.theme.mediaQueries.mobile} {

    };
    @media ${props => props.theme.mediaQueries.tablet} {

    };
    @media ${props => props.theme.mediaQueries.desktop} {
        flex-direction: row;
        width: 80%;
        margin: auto;
    };
    @media ${props => props.theme.mediaQueries.large} {
    };
`

const GameCard = styled.div`
    ${color};
    width: 60%;
    height: 150px;
    margin-bottom: 30px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.2), -4px -4px 15px rgba(0, 0, 0, 0.2);
    :hover {
        transform: translateY(-10px);
    }
    @media ${props => props.theme.mediaQueries.mobile} {
    };
    @media ${props => props.theme.mediaQueries.tablet} {

    };
    @media ${props => props.theme.mediaQueries.desktop} {
        width: 29%;
    };
    @media ${props => props.theme.mediaQueries.large} {
    };
`

const GameLink = styled.a`
    height: 100%;
    width: 100%;
    text-decoration: none;
    font-size: 25px;
    line-height: 150px;
    font-family: Amulya-Bold;
    ${color};
    ${typography};
    ${position};
    ${space};
`
