
import styled from "styled-components";
import { layout, color, space } from 'styled-system'

import { XpCard } from '../components/xp-card/xp-card'
import xpData from '../shared/xpData'

const ExperiencesPage = () => {

    const BackgroundGrid = styled.div`
        ${color};
        ${layout};
        ${space};
        display: grid;
        @media ${props => props.theme.mediaQueries.mobile} {
            grid-template-columns: repeat(12, 1fr);
        };
        @media ${props => props.theme.mediaQueries.tablet} {
            grid-template-columns: repeat(21, 1fr);
        };
        @media ${props => props.theme.mediaQueries.desktop} {
            grid-template-columns: repeat(38, 1fr);
        };
        @media ${props => props.theme.mediaQueries.large} {
            grid-template-columns: repeat(27, 1fr);
        };
    `
    const SpacingBlock = styled.div`
        @media ${props => props.theme.mediaQueries.mobile} {
            grid-column: auto / span 1;
        };
        @media ${props => props.theme.mediaQueries.tablet} {
            grid-column: auto / span 2;
        };
        @media ${props => props.theme.mediaQueries.desktop} {
            grid-column: auto / span 3;
        };
        @media ${props => props.theme.mediaQueries.large} {
            grid-column: auto / span 5;
        };
        
    `
    const SmallSpacing = styled.div`
        @media ${props => props.theme.mediaQueries.mobile} {
            display: none;
        };
        @media ${props => props.theme.mediaQueries.tablet} {
            grid-column: auto / span 1;
        };
        @media ${props => props.theme.mediaQueries.desktop} {
            grid-column: auto / span 1;
        };
    `

    const GridColumn = styled.div`
        @media ${props => props.theme.mediaQueries.mobile} {
            display: none;
        };
        @media ${props => props.theme.mediaQueries.tablet} {
            grid-column: auto / span 5;
        };
        @media ${props => props.theme.mediaQueries.desktop} {
            grid-column: auto / span 10;
        };
        @media ${props => props.theme.mediaQueries.large} {
            grid-column: auto / span 5;
        };
    `

    const GridColumnMobile = styled.div`
    @media ${props => props.theme.mediaQueries.mobile} {
        grid-column: auto / span 10;
    };
    @media ${props => props.theme.mediaQueries.tablet} {
        display: none;
    };
    @media ${props => props.theme.mediaQueries.desktop} {
        display: none;
    };
`

    return (
        <>
            <BackgroundGrid bg="darkBlue" pt={["5rem", "5rem", "9rem", "5rem"]}>
                <SpacingBlock />

                <GridColumn>
                    <XpCard cardBg="light" cardHeight={["0", "0", "190px", "270px"]} xpData={xpData[0]} animationLength="1.6s"></XpCard>
                    <XpCard cardBg="purple" cardHeight={["0", "0", "160px", "255px"]} xpData={xpData[1]} animationLength="1.75s"></XpCard>
                    <XpCard cardBg="light" cardHeight={["0", "0", "100px", "120px"]} xpData={xpData[5]} animationLength="1.8s"></XpCard>
                </GridColumn>

                <SmallSpacing />

                <GridColumn>
                    <XpCard cardBg="orange" cardHeight={["0", "0", "180px", "250px"]} xpData={xpData[3]} animationLength="1.5s"></XpCard>
                    <XpCard cardBg="light" cardHeight={["0", "0", "170px", "250px"]} xpData={xpData[4]} animationLength="1.7s"></XpCard>
                    <XpCard cardBg="light" cardHeight={["0", "0", "75px", "120px"]} xpData={xpData[6]} animationLength="2.1s"></XpCard>
                </GridColumn>

                <SmallSpacing />

                <GridColumn>
                    <XpCard cardBg="light" cardHeight={["0", "0", "180px", "250px"]} xpData={xpData[7]} animationLength="1.7s"></XpCard>
                    <XpCard cardBg="light" cardHeight={["0", "0", "200px", "270px"]} xpData={xpData[2]} animationLength="2s"></XpCard>
                    <XpCard cardBg="light" cardHeight={["0", "0", "190px", "270px"]} xpData={xpData[8]} animationLength="2s"></XpCard>
                </GridColumn>

                <GridColumnMobile>
                    <XpCard cardBg="light" cardHeight={["15rem", "15rem"]} xpData={xpData[0]}></XpCard>
                    <XpCard cardBg="light" cardHeight={["14rem", "14rem"]} xpData={xpData[3]}></XpCard>
                    <XpCard cardBg="light" cardHeight={["15rem", "15rem"]} xpData={xpData[7]}></XpCard>
                    <XpCard cardBg="light" cardHeight={["14rem", "14rem"]} xpData={xpData[1]}></XpCard>
                    <XpCard cardBg="light" cardHeight={["14rem", "14rem"]} xpData={xpData[2]}></XpCard>
                    <XpCard cardBg="light" cardHeight={["17rem", "17rem"]} xpData={xpData[4]}></XpCard>
                    <XpCard cardBg="light" cardHeight={["15rem", "15rem"]} xpData={xpData[8]}></XpCard>
                    <XpCard cardBg="light" cardHeight={["7rem", "7rem"]} xpData={xpData[5]}></XpCard>
                    <XpCard cardBg="light" cardHeight={["5rem", "5rem"]} xpData={xpData[6]}></XpCard>
                </GridColumnMobile>
                
                <SpacingBlock />
            </BackgroundGrid>
        </>
    )
}

export default ExperiencesPage;
