
import styled, {keyframes} from "styled-components";
import { layout, color, space } from 'styled-system'

const AboutPage = () => {

    return (
        <BackgroundThemed bg="darkBlue">
            <SpacingBlock />
            <MiddleBlock>
                <MainCard bg="light">
                    <ContainerSpacer />
                        <Container>
                        ABOUT

                        </Container>
                    <ContainerSpacer />
                </MainCard>
            </MiddleBlock>

            <SpacingBlock />
        </BackgroundThemed>
    )
}

const fadeIn = keyframes`
    0% { opacity 0;}
    100% { opacity 1;}
`

const BackgroundThemed = styled.div`
    ${color};
    ${layout};
    height: 100vh;
    display: grid;
    overflow-x: hidden;
    @media ${props => props.theme.mediaQueries.mobile} {
        grid-template-columns: repeat(12, 1fr);
    };
    @media ${props => props.theme.mediaQueries.tablet} {
        grid-template-columns: repeat(12, 1fr);
    };
    @media ${props => props.theme.mediaQueries.desktop} {
        grid-template-columns: repeat(24, 1fr);
    };
`

const SpacingBlock = styled.div`
    @media ${props => props.theme.mediaQueries.mobile} {
        grid-column: auto / span 2;
    };
    @media ${props => props.theme.mediaQueries.tablet} {
        grid-column: auto / span 3;
    };
    @media ${props => props.theme.mediaQueries.desktop} {
        grid-column: auto / span 6;
    };
`

const MiddleBlock = styled.div`
    display: grid;
    position: relative;
    @media ${props => props.theme.mediaQueries.mobile} {
        grid-column: auto / span 8;
    };
    @media ${props => props.theme.mediaQueries.tablet} {
        grid-column: auto / span 6;
    };
    @media ${props => props.theme.mediaQueries.desktop} {
        grid-column: span 12;
    }
`

const MainCard = styled.div`
    ${color};
    ${layout};
    opacity: 0;
    z-index: 3;
    align-self: center;
    align-items: center;
    width: 100%;
    text-align: center;
    display: inline-grid;
    box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.2), -4px -4px 15px rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    animation: ${fadeIn} 2s;
    animation-fill-mode: forwards;
    animation-delay: 1s;
    @media ${props => props.theme.mediaQueries.mobile} {
        grid-column: auto / span 8;
        grid-template-columns: repeat(8, 1fr);
        height: 400px;
    }
    @media ${props => props.theme.mediaQueries.tablet} {
        grid-column: auto / span 6;
        grid-template-columns: repeat(6, 1fr);
        height: 520px;
    }
    @media ${props => props.theme.mediaQueries.desktop} {
        grid-column: auto / span 12;
        grid-template-columns: repeat(12, 1fr);
    }
`

const Container = styled.div`
    display: grid;
    @media ${props => props.theme.mediaQueries.mobile} {
        grid-column: auto / span 6;
    }
    @media ${props => props.theme.mediaQueries.tablet} {
        grid-column: auto / span 4;
    }
    @media ${props => props.theme.mediaQueries.desktop} {
        grid-column: auto / span 8;
    }
`

const ContainerSpacer = styled.div`
    @media ${props => props.theme.mediaQueries.mobile} {
        grid-column: auto / span 1;
    }
    @media ${props => props.theme.mediaQueries.tablet} {
        grid-column: auto / span 1;
    }
    @media ${props => props.theme.mediaQueries.desktop} {
        grid-column: auto / span 2;
    }
`



export default AboutPage;
