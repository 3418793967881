import styled, {keyframes, css} from "styled-components";
import { layout, color } from 'styled-system';

import { Icons } from "../icons/icons";
import { CarouselTxt } from "../fonts/carouselTxt";
import { Title } from "../fonts/title";
import { Mecanismes } from "./mecanismes";


export const MainCard = () => {
    const fadeIn = keyframes`
        0% { opacity 0;}
        100% { opacity 1;}
    `

    const slideIn = keyframes`
        0% { 
            opacity: 0;
            transform: translateY(100%);
        } 
        100% { 
            opacity: 1;
            transform: translateY(0);
        }
    `;

    const textAnimation = {
        animation: slideIn,
        animationDelay: '2s',
        animationLength: '1.5s',
        opacity : 0
    }

    const MiddleBlock = styled.div`
        display: grid;
        position: relative;
        @media ${props => props.theme.mediaQueries.mobile} {
            grid-column: auto / span 8;
        };
        @media ${props => props.theme.mediaQueries.tablet} {
            grid-column: auto / span 6;
        };
        @media ${props => props.theme.mediaQueries.desktop} {
            grid-column: span 12;
        }
    `

    const MainCard = styled.div`
        ${color};
        ${layout};
        opacity: 0;
        z-index: 3;
        align-self: center;
        align-items: center;
        width: 100%;
        text-align: center;
        display: inline-grid;
        box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.2), -4px -4px 15px rgba(0, 0, 0, 0.2);
        border-radius: 20px;
        animation: ${fadeIn} 2s;
        animation-fill-mode: forwards;
        animation-delay: 1s;
        @media ${props => props.theme.mediaQueries.mobile} {
            grid-column: auto / span 8;
            grid-template-columns: repeat(8, 1fr);
            height: 400px;
        }
        @media ${props => props.theme.mediaQueries.tablet} {
            grid-column: auto / span 6;
            grid-template-columns: repeat(6, 1fr);
            height: 520px;
        }
        @media ${props => props.theme.mediaQueries.desktop} {
            grid-column: auto / span 12;
            grid-template-columns: repeat(12, 1fr);
        }
    `

    const Container = styled.div`
        display: grid;
        @media ${props => props.theme.mediaQueries.xsmobile} {
            grid-column: auto / span 8;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
        @media ${props => props.theme.mediaQueries.mobilewithxsmobile} {
            grid-column: auto / span 6;
        }
        @media ${props => props.theme.mediaQueries.tablet} {
            grid-column: auto / span 4;
        }
        @media ${props => props.theme.mediaQueries.desktop} {
            grid-column: auto / span 8;
        }
    `

    const ContainerSpacer = styled.div`
        @media ${props => props.theme.mediaQueries.mobile} {
            grid-column: auto / span 1;
        }
        @media ${props => props.theme.mediaQueries.tablet} {
            grid-column: auto / span 1;
        }
        @media ${props => props.theme.mediaQueries.desktop} {
            grid-column: auto / span 2;
        }
    `

    const CarouselContainer = styled.div`
    @media ${props => props.theme.mediaQueries.mobile} {
        grid-template-columns: 1fr 1fr;
        width: 100%;
    }
    @media ${props => props.theme.mediaQueries.tablet} {
        grid-template-columns: 1fr 1fr;
    }
    @media ${props => props.theme.mediaQueries.desktop} {
        grid-template-columns: 1fr 1fr;
    }
        text-align: right;
        display: grid;
        align-items: baseline;
        animation: ${({ animation, animationLength }) =>
            animation && animationLength && css`
            ${animation} ${animationLength}}
        `};
        animation-delay: ${({ animationDelay }) =>
            animationDelay && css`
            ${animationDelay}
        `};
        opacity: 0;
        animation-fill-mode: forwards;
    `
    const carouselOptions = [
        {
            color: 'pink',
            label: 'Front-end'
        },
        {
            color: 'purple',
            label: 'Web'
        },
        {
            color: 'orange',
            label: 'Angular'
        },
        {
            color: 'pink',
            label: 'Vue.js'
        },
        {
            color: 'orange',
            label: 'React'
        },
        {
            color: 'pink',
            label: 'Front-end'
        },
    ]

    return (
        <MiddleBlock>
            <Mecanismes />
            <MainCard bg="light">
                <ContainerSpacer />
                <Container>
                        <Title fontColor="darkBlue"
                            fontContent="Hi," 
                            fontType="intro" 
                            fontAlign="center"
                            widthCustom="100%"
                            fontAnimation={textAnimation}
                            fontMb="15px">
                        </Title>
                        <Title fontColor="darkBlue" 
                            fontContent="I'm Marie Pommier" 
                            fontType="main"
                            widthCustom="100%"
                            fontAnimation={textAnimation}
                            fontAlign="center">
                        </Title>
                    <CarouselContainer animation={slideIn} animationDelay="2.5s" animationLength="1.5s">
                        <CarouselTxt options={carouselOptions}/>
                        <Title fontColor="darkBlue"
                               fontContent="developer" 
                               fontType="subtitle"
                               fontAlign="left">
                        </Title>
                    </CarouselContainer>

                    <Icons></Icons>
                </Container>
                <ContainerSpacer />
            </MainCard>
        </MiddleBlock>
    )
}