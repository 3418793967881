import styled from "styled-components";
import { space, layout } from 'styled-system';

import Github from "../../assets/svg/Github.svg";
import Linkedin from "../../assets/svg/Linkedin.svg";
import Mail from "../../assets/svg/Mail.svg";

export const Icons = () => {

    return (
        <ButtonContainer mt={["20px", "20px", "40px", "45px"]}>
            <a href="https://www.linkedin.com/in/marie-pommier-000344115/">
                <Button>
                <Input
                    height={["50px", "50px", "65px", "70px"]} 
                    width={["50px", "50px", "65px", "70px"]}
                    type="image"
                    src={Linkedin}
                />
                </Button>
            </a>
            <a href="https://github.com/Tsukim09">
                <Button>
                <Input
                    height={["50px", "50px", "65px", "70px"]} 
                    width={["50px", "50px", "65px", "70px"]}
                    type="image"
                    src={Github}
                />
                </Button>
            </a>
            <a href="mailto:marie@devs-ex-machina.fr">
                <Button>
                <Input
                    height={["50px", "50px", "65px", "70px"]} 
                    width={["50px", "50px", "65px", "70px"]}
                    type="image"
                    src={Mail}
                />
                </Button>
            </a>
        </ButtonContainer>
    )
}

const ButtonContainer = styled.div`
    ${space};
    display: grid;
    @media ${props => props.theme.mediaQueries.mobile} {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
    }
    @media ${props => props.theme.mediaQueries.tablet} {
        grid-template-columns: repeat(3, 1fr);
    }
    @media ${props => props.theme.mediaQueries.desktop} {
        grid-template-columns: repeat(3, 1fr);
    }
`

const Button = styled.button`
    border: none;
    background-color: transparent;
`

const Input = styled.input`
    ${layout};
`