import styled, {keyframes} from "styled-components";
import { layout, color, space } from 'styled-system'
import { useState } from 'react';

import GameContainer from '../game-container';

const SnakePage = () => {

    return (
        <>
            <GameContainer >
                Snake
            </GameContainer>
        </>
    )
}

export default SnakePage;
