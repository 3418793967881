import './App.css';
import { BackgroundGrid } from './components/background/background';

const App = () => {
  return (
    <>
      <BackgroundGrid></BackgroundGrid>
    </>
  )
}

export default App;
