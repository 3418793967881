export const PurpleMecanisme = () => {
    return (
        <>
            <path d="M79.4761 112.083C72.9848 112.083 66.6393 110.158 61.2419 106.552C55.8446 102.945 51.6379 97.8192 49.1537 91.822C46.6696 85.8248 46.0197 79.2257 47.2861 72.8591C48.5524 66.4925 51.6783 60.6444 56.2684 56.0543C60.8584 51.4643 66.7065 48.3384 73.0731 47.072C79.4397 45.8056 86.0389 46.4555 92.0361 48.9397C98.0333 51.4238 103.159 55.6305 106.766 61.0279C110.372 66.4252 112.297 72.7708 112.297 79.2621C112.297 83.5722 111.448 87.84 109.799 91.822C108.149 95.804 105.732 99.4222 102.684 102.47C99.6362 105.518 96.0181 107.935 92.0361 109.584C88.0541 111.234 83.7862 112.083 79.4761 112.083V112.083ZM79.4761 51.4358C73.9726 51.4358 68.5927 53.0678 64.0167 56.1254C59.4407 59.183 55.8741 63.5288 53.768 68.6134C51.6619 73.698 51.1109 79.293 52.1845 84.6907C53.2582 90.0885 55.9084 95.0467 59.8 98.9382C63.6916 102.83 68.6497 105.48 74.0475 106.554C79.4453 107.627 85.0402 107.076 90.1248 104.97C95.2094 102.864 99.5553 99.2975 102.613 94.7215C105.67 90.1455 107.302 84.7656 107.302 79.2621C107.301 71.8833 104.368 64.8074 99.1498 59.5905C93.9315 54.3737 86.8549 51.4429 79.4761 51.4429V51.4358Z" fill="url(#paint0_linear_purple)"/>
            <path d="M95.965 161.021H65.7271L65.0136 142.777C59.0153 141.362 53.2494 139.099 47.8897 136.056L34.5331 148.385L13.1283 126.981L24.9224 114.223C21.4602 108.795 18.8315 102.879 17.1239 96.6714L0 95.9935V65.7485L16.339 65.0992C17.782 58.5422 20.2373 52.2499 23.6167 46.4485L12.6146 34.526L34.0194 13.1212L45.6066 23.8236C51.3969 20.2119 57.7233 17.5412 64.3501 15.9109L64.9851 0H95.223L95.858 15.968C102.462 17.7251 108.754 20.4954 114.509 24.1803L126.474 12.6431L147.879 34.048L136.413 46.4271C139.67 52.0106 142.075 58.0491 143.548 64.3429L161 65.0564V95.3014L143.098 96.0149C141.519 101.998 139.09 107.724 135.885 113.018L148.364 126.524L126.959 147.929L113.403 135.386C108.194 138.491 102.577 140.851 96.7142 142.399L95.965 161.021ZM70.5218 156.027H91.1703L91.8838 138.382L93.7389 137.954C100.35 136.416 106.65 133.762 112.368 130.106L113.995 129.071L126.838 140.951L141.407 126.36L129.578 113.517L130.634 111.89C134.381 106.093 137.099 99.6912 138.668 92.9683L139.103 91.1132L155.977 90.4425V69.844L139.431 69.1804L139.046 67.2611C137.624 60.2337 134.948 53.5196 131.147 47.4402L130.141 45.8206L140.929 34.1692L126.338 19.564L114.923 30.1095L113.289 29.025C107.101 24.9106 100.2 21.9875 92.9398 20.4059L91.0561 19.9992L90.4568 5.01587H69.8083L69.2161 19.9992L67.3182 20.3988C59.9545 21.9756 52.9557 24.9315 46.6911 29.1106L45.0857 30.1523L34.1336 20.0706L19.5426 34.6687L29.8526 45.8349L28.8466 47.4474C24.9216 53.7349 22.202 60.6984 20.8269 67.9817L20.4559 69.9367L4.97305 70.5575V91.1988L21.0552 91.841L21.4976 93.6675C23.1983 100.605 26.1269 107.183 30.1451 113.089L31.2582 114.73L20.0421 126.859L34.6402 141.457L47.2262 129.849L48.8316 130.826C54.7131 134.412 61.1701 136.954 67.9175 138.339L69.8368 138.732L70.5218 156.027Z" fill="url(#paint1_linear_purple)"/>
            <defs>
            <linearGradient id="paint0_linear_purple" x1="79.4761" y1="46.4413" x2="79.4761" y2="112.083" gradientUnits="userSpaceOnUse">
            <stop stopColor="#F4A27F"/>
            <stop offset="0.182292" stopColor="#9A87D8"/>
            <stop offset="1" stopColor="#BC84B0"/>
            </linearGradient>
            <linearGradient id="paint1_linear_purple" x1="80.5" y1="0" x2="80.5" y2="161.021" gradientUnits="userSpaceOnUse">
            <stop stopColor="#F4A27F"/>
            <stop offset="0.182292" stopColor="#9A87D8"/>
            <stop offset="1" stopColor="#BC84B0"/>
            </linearGradient>
            </defs>
        </>
    )
}