import styled, {keyframes} from "styled-components";
import { layout, color, space } from 'styled-system'

const GameContainer = (props) => {

    return (
        <>
            <GameContainerContainer bg="darkBlue">
            <BackButton color="light"><Link href="/playground">Retour</Link></BackButton>

                <SpacingBlock />
                <MiddleBlock>
                    <MainCard bg="light">
                        <Container>
                            {props.children}
                        </Container>
                    </MainCard>
                </MiddleBlock>

                <SpacingBlock />
            </GameContainerContainer>
        </>
    )
}
const fadeIn = keyframes`
    0% { opacity 0;}
    100% { opacity 1;}
`

const GameContainerContainer = styled.div`
    ${color};
    ${layout};
    height: 100vh;
    display: grid;
    overflow-x: hidden;
    @media ${props => props.theme.mediaQueries.mobile} {
        grid-template-columns: repeat(12, 1fr);
        height: 90vh;
    };
    @media ${props => props.theme.mediaQueries.tablet} {
        grid-template-columns: repeat(12, 1fr);
    };
    @media ${props => props.theme.mediaQueries.desktop} {
        grid-template-columns: repeat(24, 1fr);
    };
`

const SpacingBlock = styled.div`
    @media ${props => props.theme.mediaQueries.mobile} {
        grid-column: auto / span 1;
    };
    @media ${props => props.theme.mediaQueries.tablet} {
        grid-column: auto / span 3;
    };
    @media ${props => props.theme.mediaQueries.desktop} {
        grid-column: auto / span 6;
    };
`

const MiddleBlock = styled.div`
    display: grid;
    position: relative;
    @media ${props => props.theme.mediaQueries.mobile} {
        grid-column: auto / span 10;
        padding-top: 60px;
    };
    @media ${props => props.theme.mediaQueries.tablet} {
        grid-column: auto / span 6;
        padding-top: 60px;
    };
    @media ${props => props.theme.mediaQueries.desktop} {
        grid-column: span 12;
    }
`

const MainCard = styled.div`
    ${color};
    ${layout};
    position: relative;
    opacity: 0;
    z-index: 3;
    align-self: center;
    align-items: center;
    text-align: center;
    display: inline-grid;
    box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.2), -4px -4px 15px rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    animation: ${fadeIn} 1s;
    animation-fill-mode: forwards;
    animation-delay: 0.5s;
    padding: 15px 10px;
    @media ${props => props.theme.mediaQueries.mobile} {
        min-height: 400px;
    }
    @media ${props => props.theme.mediaQueries.tablet} {
        height: 520px;
    }
    @media ${props => props.theme.mediaQueries.desktop} {
    }
`

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    @media ${props => props.theme.mediaQueries.mobile} {
    }
    @media ${props => props.theme.mediaQueries.tablet} {
    }
    @media ${props => props.theme.mediaQueries.desktop} {
    }
`

const BackButton = styled.button`
    ${color};
    z-index: 1;
    background: none;
    border: none;
    position: absolute;
    @media ${props => props.theme.mediaQueries.mobile} {
    };
    @media ${props => props.theme.mediaQueries.tablet} {
    };
    @media ${props => props.theme.mediaQueries.desktop} {
    };
`

const Link = styled.a`
    ${space};
    color: ${props => props.theme.colors.light};
    :hover {
        color: ${props => props.theme.colors.orange};
    }
    text-decoration: none;
    @media ${props => props.theme.mediaQueries.mobile} {
        margin-bottom: 30px;
    };
    @media ${props => props.theme.mediaQueries.tablet} {
    };
    @media ${props => props.theme.mediaQueries.desktop} {
    }
`

export default GameContainer;
