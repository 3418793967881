import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import ExperiencesPage from './pages/experiences';
import AboutPage from './pages/about';
import PlaygroundPage from './pages/playgroundList';

import SnakePage from './pages/playground/snake/snake';
import MemoryPage from './pages/playground/memory/memory';
import TicTacToePage from './pages/playground/tictactoe/tictactoe';
import Puissance4Page from './pages/playground/puissance4/puissance4';

import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ThemeProvider } from 'styled-components';
import { NavBar } from './components/navbar/navbar';

const theme = {
  colors: {
    darkBlue: '#5D6996',
    light: '#F6F8FA',
    pink: '#BC84B0',
    purple: '#9A87D8',
    orange: '#F4A27F',
  },
  mediaQueries: {
    xsmobile: 'only screen and (max-width: 395px)',
    mobile: 'only screen and (max-width: 767px)',
    mobilewithxsmobile: 'only screen and (max-width: 767px) and (min-width: 396px)',
    tablet: 'only screen and (min-width: 768px)',
    desktop: 'only screen and (min-width: 1024px)',
    large: 'only screen and (min-width: 1800px)'
  },
  breakpoints: ['300px', '768px', '1024px'],
  fontSizes: [
    24, 40
  ],
  space: []
};

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
    <NavBar></NavBar>
      <Router>
        {/* <GlobalFonts /> */}
        <Switch>
          <Route exact path="/">
            <App />
          </Route>
          <Route exact path="/experiences">
            <ExperiencesPage />
          </Route>
          <Route exact path="/about">
            <AboutPage />
          </Route>
          <Route exact path="/playground">
            <PlaygroundPage />
          </Route>
          <Route exact path="/snake">
            <SnakePage />
          </Route>
          <Route exact path="/tictactoe">
            <TicTacToePage />
          </Route>
          <Route exact path="/puissance4">
            <Puissance4Page />
          </Route>
          <Route exact path="/memory">
            <MemoryPage />
          </Route>
        </Switch>
      </Router>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
