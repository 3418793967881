import styled, {keyframes} from "styled-components"

import { Title } from "./title";

export const CarouselTxt = ({options}) => {

  const getLabels = () => options.map((option, index) => {
    return (
      <Title left fontColor={option.color} fontContent={option.label} key={index} fontType="subtitle"></Title>
    )
  })

  return (
      <>
        <CarouselContainer>
          <HiddenCarousel>
            <CarouselContent>
              {getLabels()}
            </CarouselContent>
          </HiddenCarousel>
        </CarouselContainer>
      </>
  )
}

const carousel = keyframes`
  0%, 15% {transform: translateY(0);}
  20%, 35% {transform: translateY(-45px);}
  40%, 55% {transform: translateY(-90px);}
  60%, 75% {transform: translateY(-135px);}
  80%, 95% {transform: translateY(-180px);}
  100% {transform: translateY(-225px);}
`;

const CarouselContainer = styled.div`
  line-height: 45px;
  padding-right: 13px;
`
const HiddenCarousel = styled.div`
  overflow: hidden;
  height: 45px;
`
const CarouselContent = styled.div`
  position: relative;
  animation: ${carousel} 10s ease-in-out infinite;
  animation-delay: 3.5s;
`