import styled, {css} from "styled-components"
import { variant, color, typography, position, space, width } from 'styled-system'

export const Title = ({fontColor, fontType, fontContent, fontAlign, fontMb, fontAnimation, fontPt, fontPx, widthCustom}) => {

  const Text = styled.div`
    ${color};
    ${typography};
    ${position};
    ${space};
    ${width};
    animation: ${({ animation, animationLength }) =>
        animation && animationLength && css`
        ${animation} ${animationLength}}
    `};
    animation-delay: ${({ animationDelay }) =>
        animationDelay && css`
        ${animationDelay}
    `};
    animation-fill-mode: forwards;
    opacity : ${fontAnimation ? fontAnimation.opacity : '1'};
    @media ${props => props.theme.mediaQueries.mobile} {
      ${variant({
        prop: 'type',
        variants: {
          intro: {
            fontSize: '18px',
            fontFamily: 'Amulya-Medium , Arial, sans-serif',
            fontWeight: 'normal',
            fontStyle: 'normal'
          },
          main: {
            fontSize: '25px',
            fontFamily: 'Amulya-Bold , Arial, sans-serif',
            fontWeight: '700',
            fontDisplay: 'swap',
            fontStyle: 'normal'
          },
          subtitle: {
            fontSize: '18px',
            fontFamily: 'Amulya-MediumItalic, Arial, italic, sans-serif;',
            fontWeight: 'normal',
            fontStyle: 'italic'
          },
          label: {
            fontSize: '25px',
            fontFamily: 'Amulya-Bold , Arial, sans-serif',
            fontWeight: '700',
            fontDisplay: 'swap',
            fontStyle: 'normal'
          },
          smallItalic: {
            fontSize: '15px',
            fontFamily: 'Amulya-MediumItalic, Arial, italic, sans-serif;',
            fontWeight: 'normal',
            fontStyle: 'italic'
          },
          smallContent: {
            fontSize: '14px',
            fontFamily: 'Amulya-Medium , Arial, sans-serif',
            fontWeight: 'normal',
            fontStyle: 'normal'
          },
          comment: {
            fontSize: '12px',
            fontFamily: 'Amulya-MediumItalic, Arial, italic, sans-serif;',
            fontWeight: 'normal',
            fontStyle: 'italic'
          },
        }
      })};
    };
    @media ${props => props.theme.mediaQueries.tablet} {
      ${variant({
        prop: 'type',
        variants: {
          intro: {
            fontSize: '24px',
            fontFamily: 'Amulya-Medium , Arial, sans-serif',
            fontWeight: 'normal',
            fontStyle: 'normal'
          },
          main: {
            fontSize: '40px',
            fontFamily: 'Amulya-Bold , Arial, sans-serif',
            fontWeight: '700',
            fontDisplay: 'swap',
            fontStyle: 'normal'
          },
          subtitle: {
            fontSize: '24px',
            fontFamily: 'Amulya-MediumItalic, Arial, italic, sans-serif;',
            fontWeight: 'normal',
            fontStyle: 'italic'
          },
          label: {
            fontSize: '20px',
            fontFamily: 'Amulya-Bold , Arial, sans-serif',
            fontWeight: '700',
            fontDisplay: 'swap',
            fontStyle: 'normal'
          },
          smallItalic: {
            fontSize: '12px',
            fontFamily: 'Amulya-MediumItalic, Arial, italic, sans-serif;',
            fontWeight: 'normal',
            fontStyle: 'italic'
          },
          smallContent: {
            fontSize: '12px',
            fontFamily: 'Amulya-Medium , Arial, sans-serif',
            fontWeight: 'normal',
            fontStyle: 'normal'
          },
          comment: {
            fontSize: '12px',
            fontFamily: 'Amulya-MediumItalic, Arial, italic, sans-serif;',
            fontWeight: 'normal',
            fontStyle: 'italic'
          }
        }
      })};
    };
    @media ${props => props.theme.mediaQueries.desktop} {
      ${variant({
          prop: 'type',
          variants: {
            intro: {
              fontSize: '24px',
              fontFamily: 'Amulya-Medium , Arial, sans-serif',
              fontWeight: 'normal',
              fontStyle: 'normal'
            },
            main: {
              fontSize: '40px',
              fontFamily: 'Amulya-Bold , Arial, sans-serif',
              fontWeight: '700',
              fontDisplay: 'swap',
              fontStyle: 'normal'
            },
            subtitle: {
              fontSize: '24px',
              fontFamily: 'Amulya-MediumItalic, Arial, italic, sans-serif;',
              fontWeight: 'normal',
              fontStyle: 'italic'
            },
            label: {
              fontSize: '30px',
              fontFamily: 'Amulya-Bold , Arial, sans-serif',
              fontWeight: '700',
              fontDisplay: 'swap',
              fontStyle: 'normal'
            },
            smallItalic: {
              fontSize: '16px',
              fontFamily: 'Amulya-MediumItalic, Arial, italic, sans-serif;',
              fontWeight: 'normal',
              fontStyle: 'italic'
            },
            smallContent: {
              fontSize: '14px',
              fontFamily: 'Amulya-Medium , Arial, sans-serif',
              fontWeight: 'normal',
              fontStyle: 'normal'
            },
            comment: {
              fontSize: '12px',
              fontFamily: 'Amulya-MediumItalic, Arial, italic, sans-serif;',
              fontWeight: 'normal',
              fontStyle: 'italic'
            }
          }
      })};
    };
  `
    return (
        <>
        <Text color={fontColor} 
              width={widthCustom}
              textAlign={fontAlign} 
              type={fontType} 
              animation={fontAnimation?.animation}
              animationLength={fontAnimation?.animationLength}
              animationDelay={fontAnimation?.animationDelay}
              pt={fontPt}
              px={fontPx}
              mb={fontMb}>
          {fontContent}
        </Text>
        </>
    )
}