import { useState } from 'react';
import styled, {keyframes} from "styled-components";
import { space, color, typography } from 'styled-system';

import { BurgerButton } from './burger-button'


export const NavBar = () => {
    const [isNavOpen, setNavOpen] = useState(false);

    return (
        <div className="mainNavContainer">
            { isNavOpen &&
                <DarkBackground onClick={e => setNavOpen(current => !current)}></DarkBackground>
            }
            <BurgerButton onOpen={e => setNavOpen(current => !current)} isOpen={isNavOpen} ></BurgerButton>
            <NavContainer bg={isNavOpen ? 'orange' : 'darkBlue'} className={isNavOpen ? 'mobile-nav--opened' : 'mobile-nav--closed'} navOpen={isNavOpen}>
                <DesktopNavBar color="light" fontWeight='700' fontDisplay='swap' fontStyle='normal' fontFamily="Amulya-Bold, Arial, Sans-serif" fontSize={["22px", "22px", "18px", "18px"]}>
                    <Menu href="/">
                        Home
                    </Menu>
                    <Menu href="/experiences">
                        Experiences
                    </Menu>
                    {/* <Menu href="/about">
                        About me
                    </Menu> */}
                    <Menu href="/playground">
                        Playground
                    </Menu>
                </DesktopNavBar>
                 
                {isNavOpen &&
                    <MobileNavBar color="light" fontWeight='700' fontDisplay='swap' fontStyle='normal' fontFamily="Amulya-Bold, Arial, Sans-serif" fontSize={["22px", "22px", "18px", "18px"]}>
                        <Menu href="/" mt={["20px", "20px", "0px", "0px"]}>
                            Home
                        </Menu>
                        <Menu href="/experiences">
                            Experiences
                        </Menu>
                        {/* <Menu href="/about">
                            About me
                        </Menu> */}
                        <Menu href="/playground">
                            Playground
                        </Menu>
                    </MobileNavBar>
            }
            </NavContainer>
        </div>
    )
}


// const slideIn = keyframes`
// 0% { 
//     opacity: 0;
//     transform: translateX(100%);
// } 
// 100% { 
//     opacity: 1;
//     transform: translateX(0);
// }
// `


const NavContainer = styled.div`
    ${color};
    z-index: 1;
    @media ${props => props.theme.mediaQueries.mobile} {
        opacity: 0.97;
        border-bottom-right-radius: 15px;
        height: 100%;
        width: 80%;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        opacity: ${props => props.navOpen ? 0.97 : 1};
        width: ${props => props.navOpen ? '80%' : '0%'};
        box-shadow: ${props => props.navOpen ? '0px 4px 4px rgba(0, 0, 0, 0.25);' : 'none'};
        z-index: 4;
        position: fixed;
        overflow: scroll;
        left: 0;
        top: 0;
        transition: transform .5s cubic-bezier(0, .52, 0, 1);
    };
    @media ${props => props.theme.mediaQueries.tablet} {
        display: flex;
        justify-content: center;
        width: 100%;
        position: absolute;
    };
    @media ${props => props.theme.mediaQueries.desktop} {
        justify-content: flex-end;
        right: 0;
        position: absolute;
    };
`

const DesktopNavBar = styled.ul`
    ${color};
    ${typography};
    z-index: 3;
    right: 0;
    margin-right: 30px;
    padding-left: 0;
    display: flex;
    @media ${props => props.theme.mediaQueries.mobile} {
        display: none;
    };
`
const DarkBackground = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    z-index: 3;
    opacity: ${props => props.navOpen ? 0 : 0.2};
    transition: transform .5s cubic-bezier(0, .52, 0, 1);
`

const MobileNavBar = styled.ul`
    ${color};
    ${typography};
    z-index: 4;
    margin-right: 30px;
    padding-left: 0;
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    @media ${props => props.theme.mediaQueries.mobile} {
        min-height: 300px;
    };
    @media ${props => props.theme.mediaQueries.tablet} {
        display: none;
    };
`

const Menu = styled.a`
    ${space};
    margin-left: 30px;
    color: ${props => props.theme.colors.light};
    :hover {
        color: ${props => props.theme.colors.orange};
    }
    text-decoration: none;
    @media ${props => props.theme.mediaQueries.mobile} {
        margin-bottom: 30px;
    };
    @media ${props => props.theme.mediaQueries.tablet} {
    };
    @media ${props => props.theme.mediaQueries.desktop} {
    }
`