import styled, {keyframes} from "styled-components";
import { layout, color, space } from 'styled-system'
import { useEffect, useState } from 'react';

import PlayerChoice from './player-choice';
import PlayerNbr from "./pick-playernbr";
import GameContainer from '../game-container';
import { Title } from '../../../components/fonts/title'

import RestartButtonComponent from "../restart-button";

const TicTacToePage = () => {
    const [playerChoice, setPlayerChoice] = useState(null);
    const [playerNbr, setPlayerNbr] = useState(null);
    const [board, setBoard] = useState(Array(9).fill(null));
    const [isXNext, setIsXNext] = useState(true);
    const winner = calculateWinner(board);

    useEffect(() => {
        if (!!playerChoice && playerChoice === 'O' && isXNext && playerNbr === '1') {
            const newBoard = board.slice();
            setTimeout(() => {
                const move = getComputerMove(newBoard, 'X');
                newBoard[move] = 'X';
                setBoard(newBoard);
                setIsXNext(false);
            }, 300);
        }
    }, [playerChoice])

    const handleClick = (index) => {
        if (board[index] || winner) return;
        const newBoard = board.slice();
        newBoard[index] = isXNext ? 'X' : 'O';
        setBoard(newBoard);
        setIsXNext(!isXNext);

        if (playerNbr === '1') {
            if (playerChoice === 'O' && !isXNext) {
                setTimeout(() => {
                    const move = getComputerMove(newBoard, 'X');
                    newBoard[move] = 'X';
                    setBoard(newBoard);
                    setIsXNext(false);
                }, 500);
            } else if (playerChoice === 'X' && isXNext) {
                setTimeout(() => {
                    const move = getComputerMove(newBoard, 'O');
                    if (!!move || move === 0) {
                        newBoard[move] = 'O';
                        setBoard(newBoard);
                        setIsXNext(true); // Le tour revient à X
                    }
                }, 500);
            }
        }
    };

    const renderSquare = (index) => {
        const content = board[index];
        return(
        <Square onClick={() => handleClick(index)} id={`square`+ index}>
            <Title fontColor="darkBlue"
                    fontContent={content} 
                    fontType="main" 
                    fontAlign="center"
                    widthCustom="100%">
            </Title>
        </Square>)
    };

    const getStatus = () => {
        if (winner) {
            const content = `${winner} à gagné !`;
            return <Title fontColor="darkBlue"
                    fontContent={content} 
                    fontType="main" 
                    fontAlign="center"
                    fontMb="30px"
                    widthCustom="100%">
                </Title>
        } else if (board.every((square) => square)) {
            return <Title fontColor="darkBlue"
                    fontContent="Draw !" 
                    fontType="main" 
                    fontAlign="center"
                    fontMb="30px"
                    widthCustom="100%">
                </Title>
        } else {
            let content = ''
            if (playerNbr === '2') {
                content = `It's up to ${isXNext ? 'X' : 'O'}`;
            } else if (playerNbr === '1') {
                if ((playerChoice === 'O' && isXNext) ||(playerChoice === 'X' && !isXNext)) {
                    content = "Wait...";
                } else {
                    content = "It's your turn !";
                }
            }
            return <Title fontColor="darkBlue"
                    fontContent={content} 
                    fontType="infos" 
                    fontAlign="center"
                    fontMb="30px"
                    widthCustom="100%">
                </Title>
        }
    };

    const resetGame = () => {
        setBoard(Array(9).fill(null));
        setIsXNext(true);
        setPlayerChoice(null);
        setPlayerNbr(null);
    };

    return (
        <>
            <GameContainer >
                <TicTacToeContainer>
                {
                    playerNbr === null && <>
                        <PlayerNbr onChoice={(choice) => setPlayerNbr(choice)} />
                    </>
                }

                {(playerChoice === null && !!playerNbr && playerNbr === '1') && 
                    <PlayerChoice onChoice={(choice) => setPlayerChoice(choice)} />
                } 
                {((!!playerChoice && !!playerNbr) || (!!playerNbr && playerNbr === '2')) &&
                    <>
                        {getStatus()}
                        <Board>
                            {[0, 1, 2].map((row) => (
                                <BoardRow key={row}>
                                    {[0, 1, 2].map((col) => renderSquare(row * 3 + col))}
                                </BoardRow>
                            ))}
                        </Board>
                    </>
                }
                </TicTacToeContainer>
                {playerNbr !== null &&
                    <RestartButtonComponent resetGame={() => resetGame()}></RestartButtonComponent>
                }
            </GameContainer>
        </>
    )
}


const TicTacToeContainer = styled.div`
    text-align: center;
    font-size: 24px;

    @media ${props => props.theme.mediaQueries.mobile} {
        grid-column: auto / span 6;
    }
    @media ${props => props.theme.mediaQueries.tablet} {
        grid-column: auto / span 4;
    }
    @media ${props => props.theme.mediaQueries.desktop} {
        grid-column: auto / span 8;
    }
`

const Board = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const BoardRow = styled.div`
    display: flex;
`
const Square = styled.div`
    font-size: 24px;
    margin-right: -1px;
    margin-bottom: -1px;
    cursor: pointer;
    border: 1px solid #5D6996;
    display: flex;
    justify-content: center;
    align-items: center;
    @media ${props => props.theme.mediaQueries.mobile} {
        width: 60px;
        height: 60px;
    }
    @media ${props => props.theme.mediaQueries.tablet} {
        width: 80px;
        height: 80px;
    }
    @media ${props => props.theme.mediaQueries.desktop} {
        width: 100px;
        height: 100px;
    }
`
const calculateWinner = (squares) => {
    const lines = [
        [0, 1, 2],
        [3, 4, 5],
        [6, 7, 8],
        [0, 3, 6],
        [1, 4, 7],
        [2, 5, 8],
        [0, 4, 8],
        [2, 4, 6],
    ];
    for (let i = 0; i < lines.length; i++) {
        const [a, b, c] = lines[i];
        if (squares[a] && squares[a] === squares[b] && squares[a] === squares[c]) {
            return squares[a];
        }
    }
    return null;
};

const getComputerMove = (squares, player) => {
    const opponent = player === 'X' ? 'O' : 'X';
    if (!!calculateWinner(squares)) {
        return false;
    }
    // Searching for a winning hit
    for (let i = 0; i < squares.length; i++) {
        if (!squares[i]) {
            squares[i] = player;
            if (calculateWinner(squares)) {
                return i;
            }
            squares[i] = null;
        }
    }

    // Try block the player
    for (let i = 0; i < squares.length; i++) {
        if (!squares[i]) {
            squares[i] = opponent;
            if (calculateWinner(squares)) {
                return i;
            }
            squares[i] = null;
        }
    }

    // Play random
    let availableMoves = [];
    for (let i = 0; i < squares.length; i++) {
        if (!squares[i]) {
            availableMoves.push(i);
        }
    }
    return availableMoves[Math.floor(Math.random() * availableMoves.length)];
};


export default TicTacToePage;
